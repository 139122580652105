.Toastify__toast-container {
    min-height: 0 !important;
    padding: 0 !important;
    width: auto !important;
    background: none;
}

.Toastify__toast-theme--light {
    background: none !important;
    box-shadow: none;
}